<script setup>
import { validEmail } from '@/lib/validation';
import { WdsButtonPrimary, WdsIllustration, WdsInputField } from '@wds/components';

const { $tracking } = useNuxtApp();
const { useUrl } = useUrls();
const { t } = useI18n();

// ### VARIABLES INITIALIZATION
const userEmail = ref('');
const successEmail = ref(false);
const submittedNewsletter = ref(false);
const errorMessage = t('t_ENTERVALIDEMAILADDRESS');
const successMessage = t('t_THANKYOU');

// ### COMPUTED
const newsletterValidation = computed(() => {
  return submittedNewsletter.value ? (successEmail?.value ? 'success' : 'error') : null;
});

const newsletterValidationMessage = computed(() => {
  return submittedNewsletter.value ? (successEmail?.value ? successMessage : errorMessage) : null;
});

// ### METHODS
function handleSubmitNewsletter() {
  submittedNewsletter.value = true;
  successEmail.value = validEmail(userEmail?.value);
  if (!successEmail?.value) {
    return;
  }

  const trackingInfo = {
    segment: {
      event_name: 'Homepage Event',
      action: 'Newsletter Signup Clicked',
      email: userEmail?.value,
      optin_status: true,
    },
  };
  $tracking?.onNewsletterSubmit(trackingInfo.segment);
}

function setUserEmail(value) {
  userEmail.value = value;
}
</script>

<template>
  <div class="newsletter">
    <div class="newsletter-wrapper">
      <div class="header">
        <div class="header-icon">
          <WdsIllustration
            asset="mail"
            folder="md"
            loading="lazy"
            :alt="$t('t_JOINOURADVE')"
            :title="$t('t_JOINOURADVE')"
          />
        </div>

        <div class="header-content">
          <h2 class="title title-4-bld">
            {{ $t('t_JOINOURADVE') }}
          </h2>

          <h3 class="sub-title body-1-reg">
            {{ $t('t_SIGNUPNEWS') }}
          </h3>
        </div>
      </div>

      <WdsInputField
        :value="userEmail"
        type="text"
        :label="$t('t_YOUREMAIL')"
        debounce="1000"
        prefix-icon="send"
        :clear-on-focus="false"
        :status="newsletterValidation"
        :helper-text="newsletterValidationMessage"
        @input-value="setUserEmail"
      >
        <template #input-suffix>
          <div class="input-suffix-container">
            <WdsButtonPrimary
              icon-start="arrow-right"
              :aria-label="$t('t_NEWSLETTERSUBSCRIPTIONBUTTONARIALABEL')"
              @click="handleSubmitNewsletter"
            />
          </div>
        </template>
      </WdsInputField>
    </div>
    <div class="newsletter-message">
      <p
        class="text-body body-3-reg"
        v-html="t('t_NEWSLETTERMONITORING', { SECURITYPRIVACYURL: useUrl.getSecurityPrivacyUrl() })"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* VARIABLES */
$footer-top-shape-height: $wds-spacing-xxl;

@include desktop {
  $footer-top-shape-height: $wds-spacing-xxxl;
}

/* END VARIABLES */

.newsletter {
  margin-bottom: -$wds-spacing-xxl;
  clip-path: polygon(0 0, 100% 0%, 100% calc(100% - #{$footer-top-shape-height}), 0 100%);
  background-color: $wds-color-teal-light;
  padding: wds-rem(40px) $wds-spacing-m calc(#{$footer-top-shape-height} + #{$wds-spacing-xxl}) $wds-spacing-m;

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: $wds-spacing-m;

    &-icon {
      display: none;
    }

    &-content {
      .title {
        color: $wds-color-ink-darker;
      }

      .sub-title {
        color: $wds-color-ink-darker;
      }
    }
  }

  &-message {
    width: 100%;
    margin: wds-rem(10px) auto;
    text-align: center;

    p {
      color: $wds-color-teal-dark;

      :deep(a) {
        color: $wds-color-teal-dark;
        text-decoration: underline;
      }
    }
  }

  // @TODO: These values come from the old PWA homepage, need to check if we can use proper breakpoints
  @media screen and (width >= 600px) {
    padding: $wds-spacing-l $wds-spacing-l calc(#{$footer-top-shape-height} + #{$wds-spacing-l}) $wds-spacing-l;

    &-wrapper {
      width: wds-rem(552px);
      margin: 0 auto;

      .header {
        margin-right: $wds-spacing-m;
      }
    }

    .header-content .sub-title {
      margin-top: $wds-spacing-s;
    }
  }

  @include tablet-large {
    &-wrapper {
      width: wds-rem(590px);
      margin: 0 auto;

      .header {
        justify-content: center;

        &-icon {
          display: block;
          min-width: wds-rem(53px);

          img {
            width: wds-rem(53px);
            height: wds-rem(50px);
          }
        }

        &-content {
          margin-left: $wds-spacing-m;

          .title {
            @include title-3-bld;
          }

          .sub-title {
            margin-top: 0;
          }
        }
      }
    }
  }

  @include desktop {
    // el padding + clip path height
    padding-bottom: calc(#{$wds-spacing-l} + #{$footer-top-shape-height});

    &-wrapper {
      width: auto;
      display: flex;
      flex-direction: row;
      max-width: wds-rem(1062px);

      :deep(.input) {
        width: wds-rem(420px);
        margin: 0 0 0 $wds-spacing-l;
      }
    }

    &-message {
      width: wds-rem(800px);
    }
  }

  @include desktop-large {
    &-wrapper {
      max-width: wds-rem(1112px);

      :deep(.input) {
        width: wds-rem(480px);
      }
    }
  }

  .input-suffix-container {
    padding-right: $wds-spacing-xs;
  }
}
</style>
